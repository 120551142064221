// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';

// ------------- LOCAL DEPENDENCIES -------------- //

import * as Utils from '../Utils';

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  root: {
    width : '100%',
    height: '100%',
  },
  center: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    margin        : '0 auto',
  },
  hCenter: {
    textAlign: 'center',
  },
  assignmentField: {
    color     : '#000000',
    fontSize  : '14px !important',
    fontWeight: 400,
  },
  red: {
    color: '#FF0000',
  },
  green: {
    color: '#00FF00',
  },
  paidState: {
    marginRight: '10px',
  },
  assignmentValue: {
    marginRight: '10px',
  },
  cellPadding:{
    padding: 5,
  },
  headerCell: {
    color: '#757575',
  }
});

// ------------- MAIN -------------- //

class AssignmentsListChecked extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments: [],
      selected   : [],
    };
  }

  /**
   * Parses assignments collection data and presents them in an array.
   * Assignment document IDs are added to items with key "id".
   *
   * @param {JSON} _JSON -> Downloaded assignments data.
   *
   * @return {Array<JSON>} -> Parsed assignments data.
   */
  parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id             : _JSON[key].id,
        startDate      : _JSON[key].startDate,
        dueDate        : _JSON[key].dueDate,
        clientPaid     : _JSON[key].clientPaid,
        contractorPaid : _JSON[key].contractorPaid,
        clientName     : _JSON[key].clientName,
        contractorName : _JSON[key].contractorName,
        notes          : _JSON[key].notes,
        value          : _JSON[key].value,
        assignmentID   : _JSON[key].assignmentID,
        clientWeChatID : _JSON[key].clientWeChatID,
        supervisorsPaid: _JSON[key].supervisorsPaid,
      });
    }

    return parsedData;
  }

  /**
   * Updates component assignments state with passed prop "assignments".
   *
   * @param {JSON} _Props -> Updated props.
   * @param {JSON} _CurrentState -> Previous component state.
   *
   * @return {JSON} -> Next updated state.
   */
  static getDerivedStateFromProps(_Props, _CurrentState) {
    const { filterFunc } = _Props;
    let func;
    if (filterFunc) {
      func = filterFunc;
    } else {
      func = a => !a.contractorPaid;
    }
    if (_CurrentState.assignments !== _Props.assignments) {
      console.log(_Props.assignments);
      return {
        assignments: _Props.assignments,
        selected: _Props.assignments.filter(func).map(a => a.assignmentID),
      };
    }

    return null;
  }

  /**
   * Listens for assignment item click, and calls passed "onItemClick" function
   * in component props with assignment data.
   *
   * @param {function(JSON)} _Item -> Assignment data.
   */
  onItemClick(_Item) {
    const { onItemClick, } = this.props;

    if (onItemClick !== null && onItemClick !== undefined) {
      onItemClick(_Item);
    }
  }

  componentDidMount() {
    const { onSelectedUpdate } = this.props;
    const { selected } = this.state;

    onSelectedUpdate && onSelectedUpdate(selected);
  }

  perc2Color = (_Percent) => {
    return _Percent == 1 ? '#00FF00': '#FF0000';
  }

  isSelected = (_Assignment) => {
    const { selected } = this.state;
    return selected.findIndex(a => a === _Assignment.assignmentID) !== -1;
  }

  handleSelect = (_Assignment) => {
    const { onSelectedUpdate } = this.props;
    let selectedFinal = [];
    this.setState(prevState => {
      const isSelected = prevState.selected.findIndex(c => c === _Assignment.assignmentID) !== -1;
      if (_Assignment.contractorPaid) return;
      if (isSelected) {
        selectedFinal = prevState.selected.filter(c => c !== _Assignment.assignmentID);
        return {
          selected: selectedFinal,
        };
      } else {
        selectedFinal = [
          ...prevState.selected,
          _Assignment.assignmentID,
        ];
        return {
          selected: selectedFinal,
        };
      }
    }, () => onSelectedUpdate(selectedFinal));
  }

  handleSelectAll = () => {
    const {
      selected,
      assignments,
    } = this.state;
    const { onSelectedUpdate } = this.props;
    let pAssignments = this.parseJSON(assignments);
    pAssignments = pAssignments.filter(a => !a.contractorPaid);

    if (selected.length === 0) {
      const selectedFinal = pAssignments.map(a => a.assignmentID);
      this.setState({
        selected: selectedFinal,
      }, () => onSelectedUpdate(selectedFinal));
    } else {
      this.setState({
        selected: [],
      }, () => onSelectedUpdate([]));
    }
  }
  
  render() {
    const { classes, } = this.props;
    const {
      assignments,
      selected,
    }  = this.state;
    const parsedAssignments = this.parseJSON(assignments);

    return (
      <div
        className={classes.root}>
        <Table
          padding='none'>
          <TableHead>
            <TableRow>
              <TableCell
                padding = 'checkbox'
                classes = {{ paddingNone: classes.cellPadding }}>
                <Checkbox
                  indeterminate = {parsedAssignments.length > selected.length && selected.length > 0}
                  checked       = {selected.length === parsedAssignments.length}
                  onChange      = {this.handleSelectAll} />
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Start Date
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                ID
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Client
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Contractor
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                S.P.S
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Client WeChat
              </TableCell>
              <TableCell
                padding   = 'none'
                classes   = {{ paddingNone: classes.cellPadding }}
                className = { [classes.assignmentField, classes.headerCell].join(' ') }>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parsedAssignments.map((assignment, index) => {
                let numSupervisorsPaid = null;
                if (assignment.supervisorsPaid !== null && assignment.supervisorsPaid !== undefined) {
                  numSupervisorsPaid = assignment.supervisorsPaid.reduce((total, p) => { return p ? total + 1 : total} , 0);
                }
                return (
                  <TableRow
                    key     = {index}>
                    <TableCell
                      padding = 'checkbox'
                      classes = {{ paddingNone: classes.cellPadding }}>
                      <Checkbox
                        checked = {this.isSelected(assignment)}
                        onClick = {() => this.handleSelect(assignment)} />
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { Utils.timestampToReadable(assignment.startDate) }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { assignment.assignmentID }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.clientPaid ? classes.green : classes.red].join(' ') }>
                      { assignment.clientName }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.contractorPaid ? classes.green : classes.red].join(' ') }>
                      { assignment.contractorName }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { [classes.assignmentField, assignment.contractorPaid ? classes.green : classes.red].join(' ') }
                      style     = {{color: numSupervisorsPaid !== null ? this.perc2Color(numSupervisorsPaid / assignment.supervisorsPaid.length) : 'unset'}}>
                      { numSupervisorsPaid !== null ? `${numSupervisorsPaid}/${assignment.supervisorsPaid.length}` : 'N/A' }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { assignment.clientWeChatID }
                    </TableCell>
                    <TableCell
                      onClick   = { () => this.onItemClick(assignment) }
                      padding   = 'none'
                      classes   = {{ paddingNone: classes.cellPadding }}
                      className = { classes.assignmentField }>
                      { `$${assignment.value}` }
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(AssignmentsListChecked);
