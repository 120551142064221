// ------------- DEPENDENCIES ------------- //

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// ------------- MAIN ------------- ///

const config = {
  apiKey: "AIzaSyAogFFxM3l6QFfeQokEK7PIdJBU6y6tXis",
  authDomain: "production-new.firebaseapp.com",
  databaseURL: "https://production-new.firebaseio.com",
  projectId: "production-new",
  storageBucket: "production-new.appspot.com",
  messagingSenderId: "767019755726",
  appId: "1:767019755726:web:13d10be980c70e52ab889f",
  measurementId: "G-4CMTNMRBCJ",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const Auth    = firebase.auth();
const Db      = firebase.firestore();
const Storage = firebase.storage().ref();

const settings = { timestampsInSnapshots: true };
Db.settings(settings);

export {
  Auth,
  Db,
  Storage,
};
