// ------------- DEPENDENCIES -------------- //

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Select from 'react-select';
import {
  FilePond,
  registerPlugin,
  File,
} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import {
  Db,
  Storage,
} from '../firebase';
import SnackbarAlert from './SnackbarAlert';
import * as Utils from '../Utils';

import '../css/AssignmentUploadPage.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
registerPlugin(FilePondPluginImagePreview);

// ------------- CONSTANTS & GLOBAL VARS -------------- //

const styles = ({
  margin: {
    margin: 10,
  },
  card: {
    padding: 30,
  },
  root: {
    marginTop: '3%',
    width    : '100%',
    padding  : '50px 10% 30px 10%',
  },
  title: {
    marginBottom: 5,
    fontSize    : 16,
    color       : '#707070',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  button: {
    fontSize: 15,
  },
  noPadding: {
    padding: '0px !important',
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
});

// ------------- MAIN -------------- //

class AssignmentUploadPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignmentClientName         : '',
      assignmentContractorName     : '',
      assignmentDueDate            : '',
      assignmentNotes              : '',
      assignmentClientWeChatID     : '',
      assignmentSupervisorNames    : [],
      assignmentSupervisorFees     : [],
      clientNames                  : [],
      supervisorNames              : [],
      contractorNames              : [],
      assignmentCriteriaFiles      : [],
      assignmentSupervisorPaidFiles: [],
      assignmentContractorPaidFiles: [],
      assignmentClientPaidFiles    : [],
      assignmentValue              : 0,
      assignmentContractorFee      : 0,
      uploadingAssignment          : false,
      assignmentUploadStatus       : null,
      assignmentPublishStatus      : null,
    };

    this.handlePublishButtonClick            = this.handlePublishButtonClick.bind(this);
    this.renderAssignmentPublishStatusAlert  = this.renderAssignmentPublishStatusAlert.bind(this);
    this.onClientSelect                      = this.onClientSelect.bind(this);
    this.assertAssignmentCreateNotInProgress = this.assertAssignmentCreateNotInProgress.bind(this);
    this.handleCriteriaFileUpdate            = this.handleCriteriaFileUpdate.bind(this);
    this.handleContractorPaidFileUpdate      = this.handleContractorPaidFileUpdate.bind(this);
    this.handleSupervisorPaidFileUpdate      = this.handleSupervisorPaidFileUpdate.bind(this);
    this.handleClientPaidFileUpdate          = this.handleClientPaidFileUpdate.bind(this);
    this.onSupervisorSelect                  = this.onSupervisorSelect.bind(this);
  }

  /**
   * Downloads all client & contractor names from database for autocomplete.
   */
  componentDidMount = () => {
    Db.doDownloadClientNames().then((clientNames) => {
      if (clientNames === null) return;
      clientNames = clientNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        clientNames: clientNames,
      });
    });
    Db.doDownloadContractorNames().then((contractorNames) => {
      if (contractorNames === null) return;
      contractorNames = contractorNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        contractorNames: contractorNames,
      });
    });
    Db.doDownloadSupervisorNames().then((supervisorNames) => {
      if (supervisorNames === null) return;
      supervisorNames = supervisorNames.map(c => {
        return {
          label: c,
          value: c,
        };
      });

      this.setState({
        supervisorNames: supervisorNames,
      });
    });
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleCriteriaFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentCriteriaFiles: [
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Criteria File ${i + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleContractorPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentContractorPaidFiles: [
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Contractor Payment File ${i + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleClientPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentClientPaidFiles: [
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Client Payment File ${i + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }
  
  /**
   * Updates files in local component state.
   *
   * @param {Array<JSON>} _FileItems -> Array of File objects.
   */
  handleSupervisorPaidFileUpdate(_FileItems) {
    this.setState(prevState => ({
      assignmentSupervisorPaidFiles: [
        ..._FileItems.map((fileItem, i) => ({
          file    : fileItem.file,
          fileName: `Supervisor Payment File ${i + 1}${fileItem.filename.substring(fileItem.filename.lastIndexOf('.'))}`,
        }))
      ],
    }));
  }

  // Field validation states.

  /**
   * Asserts that assignment create process is not in progress. Used to
   * invalidate submit button while assignment is being uploaded.
   *
   * @return {String} -> 'success' if assignment is not being uploaded,
   *                     'error' otherwise.
   */
  assertAssignmentCreateNotInProgress() {
    const {
      uploadingAssignment,
    } = this.state;

    if (uploadingAssignment === null
      || uploadingAssignment === undefined
      || uploadingAssignment) {
      return 'error';
    } else {
      return 'success';
    }
  }
  
  /**
   * Validates 'clientName' field.
   *
   * @return {String} -> 'success' if field is valid, 'error' otherwise.
   */
  getAssignmentClientNameValidationState() {
    const {
      assignmentClientName,
    } = this.state;

    if (assignmentClientName === null
        || assignmentClientName === undefined
        || assignmentClientName === "") {
      return 'error';
    } else {
      return 'success';
    }
  }

  /**
   * Validates 'contractorName' field.
   *
   * @return {String} -> 'success' if field is valid, 'error' otherwise.
   */
  getAssignmentContractorNameValidationState() {
    const {
      assignmentContractorName,
    } = this.state;

    if (assignmentContractorName === null
        || assignmentContractorName === undefined
        || assignmentContractorName === '') {
      return 'error';
    } else {
      return 'success';
    }
  }

  /**
   * Validates 'assignmentValue' field.
   *
   * @return {String} -> 'success' if field is valid, 'error' otherwise.
   */
  getAssignmentValueValidationState() {
    const {
      assignmentValue,
    } = this.state;

    if (assignmentValue === null
        || assignmentValue === undefined
        || assignmentValue.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  /**
   * Validates 'assignmentContractorFee' field.
   *
   * @return {String} -> 'success' if field is valid, 'error' otherwise.
   */
  getAssignmentContractorFeeValidationState() {
    const {
      assignmentContractorFee,
    } = this.state;

    if (assignmentContractorFee === null
        || assignmentContractorFee === undefined
        || assignmentContractorFee.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  /**
   * Validates 'assignmentDueDate' field.
   *
   * @return {String} -> 'success' if field is valid, 'error' otherwise.
   */
  getAssignmentDueDateValidationState() {
    const {
      assignmentDueDate,
    } = this.state;

    if (assignmentDueDate === null
        || assignmentDueDate === undefined
        || assignmentDueDate.length === 0) {
      return 'error';
    } else {
      return 'success';
    }
  }

  /**
   * Uploads new assignment to database. Callback
   * to 'submit' button click event.
   *
   * @param {JSON} _Evt -> JS DOM click event object.
   */
  handlePublishButtonClick(_Evt) {
    _Evt.preventDefault();

    const {
      assignmentClientName,
      assignmentContractorName,
      assignmentClientWeChatID,
      assignmentDueDate,
      assignmentNotes,
      assignmentValue,
      assignmentContractorFee,
      assignmentCriteriaFiles,
      assignmentSupervisorNames,
      assignmentSupervisorFees,
      assignmentSupervisorPaidFiles,
      assignmentContractorPaidFiles,
      assignmentClientPaidFiles,
    } = this.state;
    const { authUser } = this.props;

    this.setState({
      uploadingAssignment: true,
    });

    const supervisorFees = assignmentSupervisorFees.map((f, i) => this.calculateFeeFromPercent(f, true));

    Db.doPublishAssignment(authUser.uid, {
      clientName             : assignmentClientName.value.split(' - ')[0],
      clientID               : assignmentClientName.value.split(' - ')[1],
      contractorName         : assignmentContractorName.value.split(' - ')[0],
      supervisorNames        : assignmentSupervisorNames.map(s => s.value.split(' - ')[0]),
      supervisorIDs          : assignmentSupervisorNames.map(s => s.value.split(' - ')[1]),
      supervisorsPaid        : Array(assignmentSupervisorNames.length).fill(false),
      supervisorsPaidDates   : Array(assignmentSupervisorNames.length).fill(null),
      contractorID           : assignmentContractorName.value.split(' - ')[1],
      clientWeChatID         : assignmentClientWeChatID,
      dueDate                : Utils.readableToTimestamp(assignmentDueDate),
      notes                  : assignmentNotes,
      value                  : assignmentValue,
      contractorFee          : assignmentContractorFee,
      clientPaid: assignmentClientPaidFiles.length > 0,
      criteriaFileNames      : assignmentCriteriaFiles.map(file => { return file.fileName }),
      supervisorPaidFileNames: assignmentSupervisorPaidFiles.map(file => { return file.fileName }),
      contractorPaidFileNames: assignmentContractorPaidFiles.map(file => { return file.fileName }),
      clientPaidFileNames    : assignmentClientPaidFiles.map(file => { return file.fileName }),
      supervisorFees         : supervisorFees.map(f => f.toFixed(2)),
    }).then((assignmentID) => {
      return Storage.doUploadCriteriaFiles(assignmentID, assignmentCriteriaFiles).then(() => {
        return Storage.doUploadSupervisorPaidFiles(assignmentID, assignmentSupervisorPaidFiles).then(() => {
          return Storage.doUploadContractorPaidFiles(assignmentID, assignmentContractorPaidFiles).then(() => {
            return Storage.doUploadClientPaidFiles(assignmentID, assignmentClientPaidFiles).then(() => {
              return assignmentID;
            });
          });
        });
      });
    }).then((assignmentID) => {
      this.setState({
        assignmentClientName         : '',
        assignmentClientWeChatID     : '',
        assignmentContractorName     : '',
        assignmentSupervisorNames    : [],
        assignmentDueDate            : '',
        assignmentNotes              : '',
        assignmentValue              : 0,
        assignmentContractorFee      : 0,
        assignmentPublishStatus      : true,
        assignmentID                 : assignmentID,
        uploadingAssignment          : false,
        assignmentCriteriaFiles      : [],
        assignmentSupervisorPaidFiles: [],
        assignmentClientPaidFiles    : [],
        assignmentContractorPaidFiles: [],
        assignmentSupervisorFees     : [],
      });
    })
  }

  /**
   * Updates local component state with selected 'clientName'
   * field, and populates WeChat ID field with downloaded value
   * associated with that client.
   *
   * @param {String} _ClientName -> Selected client name.
   */
  onClientSelect(_ClientName) {
    if (_ClientName === undefined || _ClientName === null) { return; }

    const clientID = _ClientName.value.split(' - ');

    this.setState({
      assignmentClientName: _ClientName,
    });

    if (clientID.length > 1) {
      Db.doDownloadClientData(clientID[1]).then((clientData) => {
        if (clientData !== null
            && clientData.clientWeChatID !== undefined
            && clientData.clientWeChatID !== null) {
          this.setState({
            assignmentClientWeChatID: clientData.clientWeChatID,
          });
        }
      });
    }
  }

  onSupervisorSelect(_SupervisorNames) {
    if (_SupervisorNames === undefined || _SupervisorNames === null) { return; }

    this.setState({
      assignmentSupervisorNames: _SupervisorNames,
    });

    const promises = _SupervisorNames.map((s, i) => {
      const supervisorID = s.value.split(' - ')[1];

      return Db.doDownloadSupervisorData(supervisorID);
    });

    Promise.all(promises).then((supervisors) => {
      this.setState({
        assignmentSupervisorFees     : supervisors.map(s => s.supervisorFee),
      });
    });
  }

  /**
   * Returns alert to display the assignment publish process status.
   * Handles display state based on parent state.
   *
   * @return {SnackbarAlert} -> Snackbar alert component to display.
   */
  renderAssignmentPublishStatusAlert() {
    const { assignmentPublishStatus, } = this.state;

    return (
      <SnackbarAlert
        open    = { assignmentPublishStatus !== null }
        message = { assignmentPublishStatus
          ? `The assignment has been successfully published!`
          : `There was an error, please try again later.` } />
    );
  }

  calculateFeeFromPercent = (_Percent, _NonString) => {
    const {
      assignmentValue,
      assignmentContractorFee,
    } = this.state;
    if (_NonString) return ((assignmentValue - assignmentContractorFee) * _Percent);
    return ((assignmentValue - assignmentContractorFee) * _Percent).toFixed(2);
  }

  validateDueDate = date => {
    const startDate = Date.parse('2018-01-01');
    const endDate = Date.parse('9999-01-01');
    const parsedDate = Date.parse(date);

    return parsedDate > startDate && parsedDate < endDate;
  }

  render() {
    const {
      assignmentValue,
      assignmentContractorFee,
      assignmentClientName,
      assignmentContractorName,
      assignmentClientWeChatID,
      assignmentDueDate,
      assignmentNotes,
      clientNames,
      contractorNames,
      assignmentID,
      assignmentCriteriaFiles,
      assignmentSupervisorNames,
      supervisorNames,
      assignmentSupervisorFees,
      assignmentSupervisorPaidFiles,
      assignmentContractorPaidFiles,
      assignmentClientPaidFiles,
    } = this.state;
    const { classes, } = this.props;

    const validationState = this.getAssignmentClientNameValidationState() === 'success' &&
                            this.getAssignmentContractorNameValidationState() === 'success' &&
                            this.getAssignmentValueValidationState() === 'success' &&
                            this.getAssignmentContractorFeeValidationState() === 'success' &&
                            this.getAssignmentDueDateValidationState() === 'success' &&
                            this.assertAssignmentCreateNotInProgress() === 'success';

    const supervisorFees = assignmentSupervisorFees.map((f, i) => {
      const fee = this.calculateFeeFromPercent(f, true);
      const finalVal = fee.toFixed(2);
      return {
        value: i,
        label: `$${finalVal}`,
      }
    });

    return (
      <div className={ classes.root }>
        { this.renderAssignmentPublishStatusAlert() }
        <Card
          elevation = { 8 }
          className = { classes.card }>
          <CardHeader
            classes = {{ title: classes.title, }}
            title   = 'Upload a New Assignment' />
          <div className={ [ classes.container, classes.margin ].join(' ') }>
            <FormControl fullWidth>
              <InputLabel htmlFor='assignment-id-field'>Assignment ID</InputLabel>
              <Input
                className    = {[classes.textField, classes.margin].join(' ')}
                id           = 'assignment-id-field'
                value        = {assignmentID}
                disabled     = {true}
                defaultValue = 'N/A'>
              </Input>
            </FormControl>
            <Select fullWidth
              placeholder = 'Search for client'
              className   = { classes.margin }
              value       = {assignmentClientName}
              options     = {clientNames}
              onChange    = {value => this.onClientSelect(value)} />
            <Select fullWidth
              placeholder = 'Search for contractor'
              className   = { classes.margin }
              value       = {assignmentContractorName}
              options     = {contractorNames}
              onChange    = {value => this.setState({ assignmentContractorName: value, })} />
            <Select fullWidth isMulti
              placeholder = 'Search for supervisor(s)'
              className   = { classes.margin }
              value       = {assignmentSupervisorNames}
              options     = {supervisorNames}
              onChange    = {value => this.onSupervisorSelect(value)} />
            <Select fullWidth isMulti
              placeholder = 'Supervisor Fees'
              className   = { classes.margin }
              value       = {supervisorFees}
              menuIsOpen  = {false}
              options     = {[]}
              onChange    = {(values) => console.log(values)} />
            <FormControl fullWidth>
              <InputLabel htmlFor='client-wechat-id-field'>Client WeChat ID</InputLabel>
              <Input
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'client-wechat-id-field'
                value     = {assignmentClientWeChatID}
              >
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='value-field'>Assignment Value</InputLabel>
              <Input
                startAdornment = {<InputAdornment position="start">$</InputAdornment>}
                type           = 'number'
                className      = {[classes.textField, classes.margin].join(' ')}
                id             = 'value-field'
                value          = {assignmentValue}
                onChange       = {evt => this.setState({ assignmentValue: evt.target.value, })}>
              </Input>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='contractor-fee-field'>Contractor Fee</InputLabel>
              <Input
                startAdornment = {<InputAdornment position="start">$</InputAdornment>}
                type           = 'number'
                className      = {[classes.textField, classes.margin].join(' ')}
                id             = 'contractor-fee-field'
                value          = {assignmentContractorFee}
                onChange       = {evt => this.setState({ assignmentContractorFee: evt.target.value, })}>
              </Input>
            </FormControl>
            <TextField
              fullWidth
              error={!this.validateDueDate(assignmentDueDate)}
              id              = "date"
              label           = "Due date"
              type            = "datetime-local"
              className       = {[classes.textField, classes.dateInput].join(' ')}
              InputLabelProps = {{
                shrink: true,
              }}
              value    = {assignmentDueDate}
              onChange = {evt => this.setState({ assignmentDueDate: evt.target.value, })}/>
            Criteria Files
            <FilePond
              allowMultiple = {true}
              onupdatefiles = {this.handleCriteriaFileUpdate}>
              {assignmentCriteriaFiles.map((f) =>
                <File key={f.file} src={f.file} origin="local" />
              )}
            </FilePond>
            Client Payment Files
            <FilePond
              allowMultiple = {true}
              onupdatefiles = {this.handleClientPaidFileUpdate}>
              {assignmentClientPaidFiles.map((f) =>
                <File key={f.file} src={f.file} origin="local" />
              )}
            </FilePond>
            Contractor Payment Files
            <FilePond
              allowMultiple = {true}
              onupdatefiles = {this.handleContractorPaidFileUpdate}>
              {assignmentContractorPaidFiles.map((f) =>
                <File key={f.file} src={f.file} origin="local" />
              )}
            </FilePond>
            Supervisor Payment Files
            <FilePond
              allowMultiple = {true}
              onupdatefiles = {this.handleSupervisorPaidFileUpdate}>
              {assignmentSupervisorPaidFiles.map((f) =>
                <File key={f.file} src={f.file} origin="local" />
              )}
            </FilePond>
            <FormControl fullWidth>
              <InputLabel htmlFor='notes-field'>Notes</InputLabel>
              <Input
                rows = {5}
                multiline
                className = {[classes.textField, classes.margin].join(' ')}
                id        = 'notes-field'
                value     = {assignmentNotes}
                onChange  = {evt => this.setState({ assignmentNotes: evt.target.value, })}>
              </Input>
            </FormControl>
          </div>
          <div className='submit-buttons-wrapper'>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {this.handlePublishButtonClick}
              color     = 'primary'
              disabled  = {!validationState}>
              Publish
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (_State) => ({
  authUser: _State.sessionState.authUser,
});

const mapDispatchToProps = (_Dispatch) => ({
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(AssignmentUploadPage);
