// ------------- DEPENDENCIES -------------- //

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Typography,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

// ------------- LOCAL DEPENDENCIES -------------- //

import withAuthorization from './withAuthorization';
import { Db } from '../firebase';
import * as Actions from '../reducers/action_types';
import * as Routes from '../constants/routes';
import AssignmentsListSelectable from './AssignmentsListSelectable';
import * as Utils from '../Utils';
import BulkPayDialog from './BulkPayDialog';
import SnackbarAlert from './SnackbarAlert';
import HomePageHeader from './HomePageHeader';
import homePageFilterCategories from '../constants/homePageFilterCategories';
import homePageSortCategories from '../constants/homePageSortCategories';

// ------------- LOCAL DEPENDENCIES -------------- //

const styles = ({
  assignmentsListItemDelete: {
    marginRight: '18px',
  },
  assignmentsListItem: {
    padding: '0px 5px 0px 5px !important',
  },
  assignmentsListItemTitle: {
    fontSize    : '17px',
    textOverflow: 'ellipsis',
  },
  assignmentsListItemSubtitle: {
    fontSize    : '10px',
    textOverflow: 'ellipsis',
  },
  dataContainer: {
    width    : '100%',
    padding  : '70px 5% 0px 5%',
    textAlign: 'center',
  },
  cardContainer: {
    margin: '20px',
  },
  title: {
    fontSize: 16,
    color   : '#707070'
  },
  leftAlign: {
    textAlign: 'left !important',
  },
  contractorName: {
    fontSize: 25,
  },
  contractorField: {
    margin: 10,
  },
  dateInput: {
    marginLeft  : '0px !important',
    paddingRight: '20px',
  },
  textField: {
    marginLeft : 20,
    marginRight: 20,
    fontSize   : 14,
  },
  filterWrapper: {
    padding: 10,
  },
});

// ------------- MAIN -------------- //

class ContractorData extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name                : null,
      id                  : null,
      value               : null,
      assignments         : [],
      filterStartDate     : null,
      filterEndDate       : null,
      bulkPayOpen         : false,
      bulkPayBalance      : 0,
      excluded            : [],
      clearPenaltiesStatus: null,
      isQuery: false,
      endReached: false,
    }

    this.handleAssignmentClick = this.handleAssignmentClick.bind(this);
    this.applyFilter           = this.applyFilter.bind(this);
    this.calculateFilteredFees = this.calculateFilteredFees.bind(this);
    this.parseJSON             = this.parseJSON.bind(this);
  }

  componentDidMount() {
    const {
      activeContractor,
    } = this.props;

    if (activeContractor !== null && activeContractor !== undefined) {
      this.setState({
        name: activeContractor.name,
        id  : activeContractor.contractorID,
      });

      Db.doDownloadContractorFees(activeContractor.contractorID).then((value) => {
        this.setState({
          fees: value,
        });
      });

      Db.doDownloadFilteredAssignments([{
        filter: 'contractorID',
        value : activeContractor.contractorID,
      }]).then((results) => {
        if (results !== null) {
          this.setState({
            assignments: results,
          });
        }
      });
    }
  }

  handleAssignmentClick(_Assignment) {
    const { onSetActiveAssignmentID, history, } = this.props;
    onSetActiveAssignmentID(_Assignment.id);
    history.push(Routes.ASSIGNMENT_DATA);
  }

  renderQueryErrorAlert() {
    const { queryError, } = this.state;

    return (
      <SnackbarAlert
        autoHideDuration = {6000}
        onClose          = {() => this.setState({ queryError: null })}
        open             = { queryError !== null }
        message          = { queryError} />
    );
  }

  calculateFilteredFees(_Assignments) {
    const {
      assignments,
    } = this.state;
    const {
      activeContractor,
    } = this.props;
    const assignm           = _Assignments === undefined ? assignments : _Assignments;
    const parsedAssignments = this.parseJSON(assignm);

    let sum = 0;
    for (let i = 0; i < parsedAssignments.length; i++) {
      if (parsedAssignments[i].contractorFee &&
        !parsedAssignments[i].contractorPaid &&
        !parsedAssignments[i].refunded &&
        !parsedAssignments[i].clientPaid) {
        sum += parseInt(parsedAssignments[i].contractorFee);
      }
    }

    return Math.max(0, sum - (activeContractor.penalty || 0));
  }

  calculateBulkPayBalance = () => {
    const {
      assignments,
      excluded,
    } = this.state;
    const filteredAssignments = assignments.filter(a =>
      !a.contractorPaid &&
      !excluded.includes(a.assignmentID) &&
      a.clientPaid &&
      a.paymentApproval &&
      !a.refunded
    );

    let sum = filteredAssignments.reduce((acc, curr) => acc + parseFloat(curr.contractorFee), 0);
    return sum;
  }

  onQueryError = error => {
    this.setState({
      queryError: error,
    });
  }

  parseJSON(_JSON) {
    const parsedData = [];
    
    for (const key in _JSON) {
      parsedData.push({
        id            : _JSON[key].id,
        startDate     : _JSON[key].startDate,
        dueDate       : _JSON[key].dueDate,
        clientPaid    : _JSON[key].clientPaid,
        contractorPaid: _JSON[key].contractorPaid,
        clientName    : _JSON[key].clientName,
        contractorName: _JSON[key].contractorName,
        notes         : _JSON[key].notes,
        value         : _JSON[key].value,
        contractorFee : _JSON[key].contractorFee,
      });
    }

    return parsedData;
  }

  applyFilter() {
    const {
      filterStartDate,
      filterEndDate,
    } = this.state;
    const {
      activeContractor
    } = this.props;

    Db.doDownloadRangedAssignments(
      Utils.readableToTimestamp(filterStartDate),
      Utils.readableToTimestamp(filterEndDate),{
        field: 'contractorID',
        value: activeContractor.contractorID,
      }).then((results) => {
      if (results !== null) {
        this.setState({
          assignments: results,
        });
      }
    });
  }

  openBulkPay = () => {
    this.setState({
      bulkPayOpen: true,
    });
  }

  bulkPayContractor = () => {
    const { history } = this.props;
    this.setState({
      bulkPayOpen: false,
    });
    history.push(Routes.CONTRACTOR_LIST);
  }

  handleSelectedUpdate = (_Selected) => {
    const { assignments } = this.state;
    this.setState({
      excluded: assignments.filter(a => !_Selected.includes(a.assignmentID) && !a.contractorPaid).map(a => a.assignmentID),
    });
  }

  clearPenalties = () => {
    const {
      activeContractor,
      history,
    } = this.props;
    const {
      id,
    } = this.state;

    if (activeContractor === null) return;

    Db.doReduceContractorPenalty(id, activeContractor.penalty + 10).then(() => {
      this.setState({
        clearPenaltiesStatus: true,
      });
      setTimeout(() => {
        history.goBack();
      }, 500);
    }).catch(() => {
      this.setState({
        clearPenaltiesStatus: false,
      });
    });
  };

  onQueryUpdate = (_Results, _IsQuery) => {
    if (_Results === null) {
      this.applyFilter();
    } else {
      this.setState({
        assignments: _Results || [],
        isQuery    : _IsQuery,
        endReached : _IsQuery,
      });
    }
  }

  render() {
    const {
      name,
      fees,
      id,
      assignments,
      filterStartDate,
      filterEndDate,
      bulkPayOpen,
      excluded,
      clearPenaltiesStatus,
    } = this.state;
    const {
      classes,
      activeContractor,
    } = this.props;

    return (
      <div className={classes.dataContainer}>
        <Card
          className = {classes.cardContainer}
          elevation = {8}>
          <CardHeader
            classes = {{ title: [classes.leftAlign, classes.title].join(' ') }}
            title   = 'Contractor Data'/>
          <CardContent>
            <Typography
              className = { classes.contractorName }
              variant   = 'headline'
              component = 'p'>
              {name}
            </Typography>
            <Typography
              className = { classes.contractorField }
              variant   = 'headline'
              component = 'p'>
              ID: {id}
            </Typography>
            <Typography
              className = { classes.contractorField }
              variant   = 'headline'
              component = 'p'>
              Outstanding Penalties: ${(activeContractor && activeContractor.penalty) || 0}
            </Typography>
            <Typography
              className = { classes.contractorField }
              variant   = 'headline'
              component = 'p'>
              Aggregated Fees: ${fees}
            </Typography>
            <Typography
              className = { classes.contractorField }
              variant   = 'headline'
              component = 'p'>
              Filtered Unpaid Fees: ${this.calculateBulkPayBalance()}
            </Typography>
            <Button
              className = {[classes.margin, classes.button].join(' ')}
              size      = 'large'
              onClick   = {() => this.openBulkPay()}
              color     = 'primary'
              disabled  = {false}>
              Bulk Pay
            </Button>
            <Button
              variant='contained'
              style={{
                backgroundColor: 'red',
                color          : 'white',
                marginLeft     : 50,
              }}
              onClick={this.clearPenalties}>
              CLEAR OUTSTANDING PENALTIES
            </Button>
          </CardContent>
        </Card>
        <Paper
          className={ [classes.filterWrapper, classes.center].join(' ') }>
          <TextField
            id              = "dateStart"
            label           = "Start Date"
            type            = "date"
            className       = {[classes.textField, classes.dateInput].join(' ')}
            InputLabelProps = {{
              shrink: true,
            }}
            value    = {filterStartDate}
            onChange = {evt => this.setState({ filterStartDate: evt.target.value, })}/>
          <TextField
            id              = "dateEnd"
            label           = "End Date"
            type            = "date"
            className       = {[classes.textField, classes.dateInput].join(' ')}
            InputLabelProps = {{
              shrink: true,
            }}
            value    = {filterEndDate}
            onChange = {evt => this.setState({ filterEndDate: evt.target.value, })}/>
          <Button
            className = {[classes.margin, classes.button].join(' ')}
            size      = 'large'
            onClick   = {() => this.applyFilter()}
            color     = 'primary'
            disabled  = {false}>
            Apply
          </Button>
        </Paper>
        <Paper>
          <HomePageHeader
            defaultFilters={[
              {
                filter: 'contractorID',
                value : activeContractor.contractorID,
              }
            ]}
            filterCategories = {homePageFilterCategories}
            sortCategories = {homePageSortCategories}
            onQueryUpdate = {this.onQueryUpdate}
            onError = {this.onQueryError}
          />
          {
            assignments === null
              ? <ReactLoading
                  type  = 'bars'
                  style = {{
                    margin   : 'auto',
                    width    : '20%',
                    color    : "#29aafc",
                    marginTop: '200px',
                  }}/>
              : assignments !== null && assignments.length === 0
                  ? <div>No matching results</div>
                  : <AssignmentsListSelectable
                      onSelectedUpdate = {this.handleSelectedUpdate}
                      onItemClick      = {this.handleAssignmentClick}
                      assignments      = {assignments}
                      filterFunc={assignment =>
                        !assignment.contractorPaid &&
                        assignment.clientPaid &&
                        assignment.paymentApproval &&
                        !assignment.refunded
                      } 
                    />
          }
        </Paper>
        <BulkPayDialog
          totalFees       = {`$${this.calculateBulkPayBalance()}`}
          excluded        = {excluded}
          contractorID    = {id}
          open            = {bulkPayOpen}
          onCancelHandler = {() => this.setState({ bulkPayOpen: false, })}
          onOKHandler     = {this.bulkPayContractor} />
        <SnackbarAlert
          open    = { clearPenaltiesStatus !== null }
          message = { clearPenaltiesStatus
            ? `Penalties successfully cleared!`
            : `There was an error, please try again later.` } />
        {this.renderQueryErrorAlert()}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

const mapStateToProps = (_State) => ({
  authUser        : _State.sessionState.authUser,
  activeContractor: _State.contractorState.activeContractor,
});

const mapDispatchToProps = (_Dispatch) => ({
  onSetActiveAssignmentID: (activeAssignmentID) => _Dispatch({ type: Actions.ACTIVE_ASSIGNMENT_SET, activeAssignmentID }),
});

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(ContractorData);
